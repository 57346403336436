// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section+.content-section,
.lead-section+.bundle-detail-section,
.lead-section+.bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;

	p {
		@extend .text-large;
	}

	.container-one-column {
		max-width: 850px;

		.container-holder {
			text-align: center;
		}
	}

	// bundles
	.info-nav {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

&.accommodation-detail {
	.lead-section {
		max-width: 1380px;
		margin: 0 15px 30px 15px;
		padding-top: 30px;
		background-color: $cream;
		border-radius: 20px;

		@media (min-width: 1410px) {
			margin-left: auto;
			margin-right: auto;
		}

		.info-nav {
			margin-bottom: 0;

			.scroll-to-content {
				position: absolute;
				bottom: -21px;
				left: 0;
				right: 0;
			}
		}

		.info {
			display: flex;
			flex-direction: column;
			margin-bottom: 30px;

			.title {
				order: 1;
			}

			.wysiwyg {
				order: 2;
			}

			.properties {
				order: 3;
				margin: 5px 0 45px 0;

				li {
					z-index: 1;
					position: relative;
					display: flex;
					align-items: center;
					flex-direction: column;
					justify-content: center;
					min-height: 55px;
					min-width: 55px;

					&::before {
						content: '';
						z-index: -1;
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						width: 55px;
						margin: auto;
						background-color: $red-light;
						border-radius: 100%;
						aspect-ratio: 1;
					}

					.icon {
						display: block;
						margin-right: 0;
						color: $white;
					}

					.label {
						display: block;
						color: $red-dark;
						font-size: 13px;
						font-weight: 700;
						font-family: $font-family-secondary;
					}
				}
			}
		}
	}
}

// home-section
// =========================================================================
.home-section {
	background-color: $cream-light;

	// container-two-columns
	.container-two-columns {
		.container-holder {
			align-items: center;

			.column {
				&.one {
					@include media-breakpoint-up(lg) {
						@include make-col(7);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(8);
					}

					padding-right: 15px !important;
				}

				&.two {
					@include media-breakpoint-up(lg) {
						@include make-col(5);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(4);
					}

					flex-flow: row wrap;
					justify-content: space-around;
					padding-left: 15px !important;
				}
			}
		}

	}
}

// usps-section
// =========================================================================
.usps-section {
	margin: 6vh 0;
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		&.grid {
			.grid-items {
				.item {
					margin-bottom: calc(30px + 21px);
				}
			}
		}

		.card {
			@extend .default-card !optional;
		}
	}
}

&.home {
	.collection-section.custom {
		position: relative;
		margin: 0;
		padding-top: 6vh;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 6vh;
			background: linear-gradient(0deg, rgba($cream-light, 0) 0%, rgba($cream-light, 1) 100%);
		}
	}
}

// service-collection-section
// =========================================================================
.service-collection-section {
	max-width: 1380px;
	margin: 6vh 15px;
	padding: 6vh 0;
	background-color: $green-light;
	border-radius: 20px;

	@media (min-width: 1410px) {
		margin-left: auto;
		margin-right: auto;
	}

	// wysiwyg
	.wysiwyg {
		color: $white;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $cream-light;
		}

		ul {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			margin: -15px -15px 0 -15px;
			padding: 0;
			list-style: none;

			li {
				margin: 15px 15px 0 15px;
			}
		}
	}

	// collection
	.collection {
		&.grid {
			.grid-items {
				justify-content: flex-start;

				.item {
					@include media-breakpoint-only(xs) {
						@include make-col(6);
					}
				}
			}
		}

		.card {
			border-radius: 0;
			border: none;
			background: none;
			overflow: visible;

			.card-image {
				border-radius: 20px;
				overflow: hidden;
			}

			.card-body {
				padding: 15px 0;

				.card-caption {
					color: $white;

					.card-title {
						margin-bottom: 0;
						color: $cream-light;
						@include font-size($h4-font-size);

						@include media-breakpoint-down(sm) {
							font-size: 16px;
						}

						&:hover {
							color: $red;
						}
					}

					.card-subtitle {
						position: absolute;
						top: 15px;
						right: -15px;
						display: flex;
						align-items: center;
						justify-content: center;
						width: 120px;
						margin: 0;
						aspect-ratio: 1;
						background-color: $green-neon;
						border-radius: 100%;
						box-shadow: -5px 5px 0 0 $green-light;
						color: $green-dark;
						font-size: 20px;
						text-align: center;
						overflow: hidden;

						@include media-breakpoint-down(sm) {
							top: 10px;
							right: -5px;
							width: 70px;
							font-size: 11px;
							box-shadow: -2px 2px 0 0 $green-light;
						}
					}

					.card-description {
						margin-top: 0.5rem;

						@include media-breakpoint-down(sm) {
							display: none;
						}
					}
				}

				.card-buttons {
					display: none;
				}
			}
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
	position: relative;
	max-width: 1380px;
	margin: 6vh 15px;

	@media (min-width: 1410px) {
		margin-left: auto;
		margin-right: auto;
	}

	.owl-carousel {
		.item {
			flex-direction: column;
			padding-bottom: 21px;

			picture {
				border: 10px solid $cream;
				border-radius: 25px;
				height: 420px;
				overflow: hidden;

				@include media-breakpoint-up(xl) {
					height: 720px;
				}

				@include media-breakpoint-down(lg) {
					border-width: 5px;
				}

				@include media-breakpoint-down(md) {
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
				}
			}

			.owl-caption-holder {
				position: static;

				.owl-container {
					max-width: 900px;
					margin-top: -45px;
					padding-left: 0;
					padding-right: 0;

					.owl-caption {
						position: relative;
						padding: 85px 15px 0 15px;
						background: $cream;
						border-radius: 20px;
						color: $green-dark;
						text-shadow: none;

						@include media-breakpoint-up(md) {
							padding: 45px 15% 0 15%;
						}

						.owl-title {
							margin-bottom: 15px;
						}

						.owl-subtitle {
							position: absolute;
							right: 25px;
							bottom: calc(100% - 85px);
							display: flex;
							align-items: center;
							justify-content: center;
							width: 150px;
							padding: 10px;
							aspect-ratio: 1;
							background-color: $green-neon;
							border-radius: 100%;
							box-shadow: -4px 4px 0 0 $green;
							color: $green-dark;
							font-size: 16px;
							text-align: center;
							overflow: hidden;

							@include media-breakpoint-up(md) {
								right: 50px;
								bottom: calc(100% - 45px);
							}

							@include media-breakpoint-up(xl) {
								width: 175px;
								box-shadow: -6px 6px 0 0 $green;
								font-size: 20px;
							}
						}

						.owl-description {
							margin-bottom: 30px;
							@extend .text-large;
						}

						.owl-btn {
							margin-bottom: -21px;
						}
					}
				}
			}
		}

		.owl-nav,
		.owl-dots {
			display: none;
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		&.grid {
			.grid-items {
				.item {
					margin-bottom: calc(30px + 21px);
				}
			}
		}

		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

&.accommodation-detail,
&.service-detail {
	.gallery-section {
		.container {
			max-width: 1280px;
		}
	}
}

&.accommodation-detail {
	.gallery-section {
		margin-top: 6vh;
	}
}


// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}
