// mini-sab
.mini-sab {
	margin-bottom: 6vh;

	@include media-breakpoint-down(md) {
		margin-top: 10px;
	}

	.container {
		position: relative;
		display: flex;
		justify-content: center;
		max-width: none;

		.container-holder {
			margin: 0;
			padding: 15px 0 20px 0;
			background: $cream;
			border-radius: 0 0 25px 25px;

			@include media-breakpoint-up(lg) {
				z-index: 100;
				position: relative;
				margin-top: -54px;
				padding: 20px 5px;
				border-radius: 25px;
			}
		}
	}
}
