// header
.header {
	@include media-breakpoint-up(xl) {
		z-index: 998;
		position: sticky;
		top: 0;
		left: 0;
		right: 0;
	}

	&.sticky {
		background-color: $cream-light;
		box-shadow: $shadow;
	}

	.container,
	.container-fluid {
		.container-holder {
			>.column {
				align-items: center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		z-index: 997;
		position: fixed;
		top: 37px;
		left: 15px;

		.navbar-toggler {
			width: 42px;
			height: 42px;
			padding: 0;
			border-radius: 100%;
			background-color: $cream;
			border: none;
			color: $green-dark;
			font-size: 24px;
			transition: 0.5s;

			i {
				line-height: 42px;
				transition: transform 0.5s;
			}

			&[aria-expanded="true"] {
				i {
					transform: rotate(180deg);

					&::before {
						content: "\f00d";
					}
				}
			}

			&:hover {
				background: $red;
				color: $white;
			}
		}
	}

	// logo
	.logo {
		z-index: 100;

		@include media-breakpoint-up(xl) {
			width: 340px;
			margin: 0 auto -65px 55px;
			transition: 0.5s;
		}

		@include media-breakpoint-down(lg) {
			width: 100%;
			order: 5;
			margin: 15px 0 -50px 0;

			a {
				width: 260px;
				margin: 0 auto;
			}
		}

		a {
			display: block;

			img {
				@extend .w-100;
			}
		}
	}

	&.sticky {
		.logo {
			@include media-breakpoint-up(xl) {
				width: 260px;
				margin-bottom: -45px;
			}
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			z-index: 997;
			position: fixed;
			top: 79px;
			left: 15px;
			right: 15px;
			margin-top: 10px;
			border-radius: 20px;
			background-color: $cream;
			padding: 10px 15px;
			font-family: $font-family-secondary;

			.navbar-nav {
				>.nav-item {
					>.nav-link {
						display: flex;
						align-items: center;
						color: $green-dark;
						font-size: 16px;
						font-weight: 700;

						&:hover {
							color: $red;
						}
					}

					&.active {
						>.nav-link {
							color: $red;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0 0 0 15px;
						padding: 0;
						border: none;
						border-radius: 6px;
						box-shadow: none;
						background: transparent;

						.nav-item {
							font-size: 15px;

							.nav-link {
								color: $green-dark;

								&:hover {
									color: $red;
								}
							}

							&:first-of-type {
								.nav-link {
									//padding-top: 0;
								}
							}

							&.active {
								>.nav-link {
									color: $red;
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			flex-grow: 0;

			.navbar-nav {
				>.nav-item {
					margin-right: 10px;
					font-weight: 700;
					font-family: $font-family-secondary;

					>.nav-link {
						color: $green-dark;

						&:hover {
							color: $red;
						}
					}

					&.active {
						>.nav-link {
							color: $red;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						padding: 0.5rem;
						border-radius: 10px;
						background-color: $cream;
						border-color: $cream;

						&::before,
						&::after {
							border-bottom-color: $cream;
						}

						.nav-item {
							font-weight: 400;
							line-height: 1.2;
							white-space: nowrap;

							.nav-link {
								color: $green-dark;

								&:hover {
									color: $red;
								}
							}

							&.active {
								.nav-link {
									color: $red;
								}
							}
						}
					}
				}
			}
		}
	}

	// book-button
	div.book-button {
		@include media-breakpoint-down(lg) {
			order: 3;
			margin-left: 10px;
		}

		a {
			@extend .btn;
			@extend .btn-primary;

			@include media-breakpoint-down(lg) {
				line-height: 16px !important;
				font-size: 14px !important;
			}
		}
	}

	// site-switcher
	.site-switcher {
		margin-left: 10px;

		@include media-breakpoint-down(lg) {
			order: 2;
			margin-left: auto;
		}
	}

	// news-ticker
	div.news-ticker {
		padding: 10px 0;
		font-size: 14px;
		white-space: nowrap;
		overflow: hidden;

		ul {
			display: block !important;
			animation: ticker 60s linear infinite;

			li {
				display: inline-block;
				color: $green-dark;

				&::before {
					content: '****';
					margin: 0 25px;
					color: $cream;
					font-size: 14px;
					vertical-align: middle;
				}

				a {
					color: $green-dark;
					text-decoration: none;

					.list-item-suffix {
						padding-left: 3px;
						color: $red-dark;
						text-decoration: underline;
					}

					&:hover {
						.list-item-suffix {
							color: $green-dark;
						}
					}
				}
			}
		}
	}

	@keyframes ticker {
		0% {
			transform: translateX(0);
		}

		100% {
			transform: translateX(-100%);
		}
	}
}
