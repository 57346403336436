// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 420px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 85vh;
	min-height: 460px;
	max-height: 720px;

	@include media-breakpoint-up(sm) {
		height: 100vh;
	}
}

// eyecatcher
.eyecatcher {
	position: relative;
	max-width: 1380px;
	margin: 0 15px;

	@media (min-width: 1410px) {
		margin-left: auto;
		margin-right: auto;
	}

	// owl-carousel
	.owl-carousel {
		.owl-stage-outer {
			.owl-stage {
				padding-top: 10px;
				padding-bottom: 10px;
			}
		}

		.item {
			border: 10px solid $cream;
			border-radius: 25px;
			background: $cream;

			@include media-breakpoint-down(lg) {
				border-width: 5px;
			}

			@include media-breakpoint-down(md) {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
			}

			picture {
				img {
					border-radius: 15px;

					@include media-breakpoint-down(lg) {
						border-radius: 20px;
					}
				}
			}

			.owl-caption-holder {
				z-index: 100;
				position: absolute;
				width: auto;

				@include media-breakpoint-down(md) {
					top: auto;
					left: auto;
					right: 30px;
					bottom: -5px;
				}

				@include media-breakpoint-only(lg) {
					top: -10px;
					left: auto;
					right: 30px;
					bottom: auto;
				}

				@include media-breakpoint-up(xl) {
					top: -10px;
					left: auto;
					right: 130px;
					bottom: auto;
				}

				.owl-container {
					max-width: none;
					padding: 0;

					.owl-caption {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						color: $green-dark;
						text-shadow: none;
						width: 165px;
						padding: 5px;
						aspect-ratio: 1;
						background-color: $green-neon;
						border-radius: 100%;
						box-shadow: -4px 4px 0 0 $green;
						overflow: hidden;

						@include media-breakpoint-up(xl) {
							width: 200px;
							box-shadow: -6px 6px 0 0 $green;
						}

						.owl-title {
							margin-bottom: 0;
							font-size: 30px;

							@include media-breakpoint-up(xl) {
								font-size: 38px;
							}
						}

						.owl-subtitle {
							margin: 5px 0;
							font-size: 16px;

							@include media-breakpoint-up(xl) {
								font-size: 20px;
							}
						}

						.owl-description {
							margin-bottom: 0;

							@include media-breakpoint-down(lg) {
								font-size: 14px;
							}

							p {
								margin-bottom: 0;
							}
						}

						.owl-btn {
							margin-top: 5px;
							padding: 0 !important;
							background: none !important;
							border: none !important;
							color: $red-dark;
							font-weight: 700;

							&:hover {
								color: $green !important;
							}
						}
					}
				}
			}
		}

		.owl-nav,
		.owl-dots {
			display: none;
		}
	}

	// eyecatcher-overlay
	.eyecatcher-overlay {
		z-index: 100;
		position: absolute;
		top: 100px;
		left: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;

		@include media-breakpoint-up(lg) {
			top: 50%;
			transform: translateY(-50%);
		}

		.logo-visual {
			width: 40vw;
			max-width: 535px;
			min-width: 240px;
			margin: 0 auto 10px auto;
		}

		.page-title {
			h1 {
				margin-bottom: 0;
				color: $white;
				text-align: center;

				@include media-breakpoint-up(xl) {
					font-size: 64px;
				}
			}
		}
	}
}
