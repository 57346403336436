// main
.main {
	position: relative;
	z-index: 1;
	background: url('/images/bg-pattern.svg');

	&::before {
		content: '';
		z-index: -1;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 6vh;
		background: linear-gradient(0deg, rgba($cream-light, 0) 0%, rgba($cream-light, 1) 100%);
	}

	&::after {
		content: '';
		z-index: -1;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 12vh;
		background: linear-gradient(0deg, rgba($cream-light, 1) 0%, rgba($cream-light, 0) 100%);
	}

}

// container
.container {
	&.container-small {
		@include media-breakpoint-up(sm) {
			max-width: 540px;
		}
	}

	&.container-medium {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}
	}

	&.container-large {
		@include media-breakpoint-up(lg) {
			max-width: 960px;
		}
	}

	&.container-intro {
		@include media-breakpoint-up(md) {
			//max-width: 720px;
		}

		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		@include media-breakpoint-up(md) {
			//max-width: 720px;
		}

		margin-top: 30px;
		text-align: center;
	}
}

.container-fluid {
	max-width: 1380px;
}

// headings
&.home {
	h2 {
		@include media-breakpoint-up(xl) {
			font-size: $h1-font-size;
		}
	}
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// text-large
.text-large {
	@include media-breakpoint-up(lg) {
		font-size: 24px;
		line-height: 1.2;
	}
}

// form
.form {
	padding: 15px;
	background-color: $cream;
	border-radius: 20px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}
}

// default-card
.default-card {
	border: 5px solid $cream-dark;
	border-radius: 20px;
	background-color: $cream-dark;
	overflow: visible;

	.card-image {
		border-radius: 15px;
		overflow: hidden;
	}

	.card-body {
		@include media-breakpoint-up(xl) {
			padding-left: 25px;
			padding-right: 25px;
		}

		.card-caption {
			color: $white;

			.card-title-link {
				transition: none;
			}

			.card-subtitle {
				position: absolute;
				top: 15px;
				right: -15px;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 120px;
				margin: 0;
				aspect-ratio: 1;
				background-color: $green-neon;
				border-radius: 100%;
				box-shadow: -5px 5px 0 0 $green-light;
				color: $green-dark;
				font-size: 20px;
				text-align: center;
				overflow: hidden;
			}
		}

		.card-info {
			color: $white;
		}

		.card-buttons,
		.card-actions {
			margin-top: 0;

			.card-btn {
				transform: translateY(42px);
			}
		}
	}
}

// gallery
.gallery,
.gallery-horizontal {
	.gallery-item {
		.gallery-link {
			border-radius: 20px;
		}
	}
}

// icon-collection
.icon-collection {
	&.grid {
		.grid-items {
			@include media-breakpoint-down(md) {
				margin-left: 0;
				margin-right: 0;
			}

			.item {
				@include media-breakpoint-down(md) {
					padding-left: 0;
					padding-right: 0;
				}

				@include media-breakpoint-down(md) {
					@include make-col(4);
				}

				.card {
					z-index: 1;
					display: flex;
					align-items: center;
					justify-content: center;
					background: none;
					border: none;
					border-radius: 0;
					overflow: visible;

					@include media-breakpoint-down(md) {
						width: 100px;
						height: 100px !important;
						margin: 0 auto;
						background-color: $red-light;
						border-radius: 100%;
					}

					@include media-breakpoint-up(lg) {
						min-height: 145px;

						&::before {
							content: '';
							z-index: -1;
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							width: 145px;
							margin: auto;
							background-color: $red-light;
							border-radius: 100px;
							aspect-ratio: 1;
						}
					}

					.card-image {
						flex: none;
						margin: 0 auto 5px auto;

						i {
							color: $white;
							font-size: 36px;

							@include media-breakpoint-down(md) {
								font-size: 30px;
							}
						}
					}

					.card-body {
						flex: none;
						padding: 0;
						text-align: center;

						.card-caption {
							.card-title {
								margin-bottom: 10px;
								color: $red-dark;
								font-size: 20px;
								font-weight: 700;

								@include media-breakpoint-down(md) {
									font-size: 14px;
								}
							}

							.card-subtitle {
								margin: 0;
								font-size: 14px;
								font-weight: 400;
								line-height: 1.2;
								font-family: $font-family-primary;

								@include media-breakpoint-down(lg) {
									display: none;
								}
							}

							.card-description {
								font-size: 14px;

								p {
									margin-bottom: 0;
								}

								@include media-breakpoint-down(lg) {
									display: none;
								}
							}
						}
					}

					&:hover {
						transform: scale(1.05);
					}
				}
			}
		}
	}
}

// image-collection
.image-collection {
	&.owl-carousel {
		.item {
			picture {
				width: 100%;
				height: 100%;

				img {
					height: 100%;
					object-fit: cover;
				}
			}

			.owl-caption-holder {
				z-index: 100;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
}
