.faq-overview {
    .faq-category {
        .faq-title {
            h2 {
                font-size: $h2-font-size;
            }
        }

        .faq-items {
            .faq-item {
                .faq-question {
                    h3 {
                        font-size: $h5-font-size;
                    }
                }
            }
        }
    }
}