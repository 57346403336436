// btn
.btn {
	padding: 0.5rem 1.5rem;
	border-radius: 21px;
	font-weight: 700;
	line-height: 24px;
	font-family: $font-family-secondary;

	// btn-primary
	&.btn-primary {
		&:hover {
			border-color: $red-dark;
			background-color: $red-dark;
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 25px;
	color: $red-dark;
	font-weight: 700;
	font-family: $font-family-secondary;
	text-decoration: none;

	&::before {
		content: "\f137";
		position: absolute;
		left: 0;
		color: $red;
		font-size: 20px;
		font-weight: 400;
		font-family: $font-awesome;
	}

	&:hover {
		color: $red;
	}
}

// btn-special
.btn-special {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-right: calc(24px + 2.25rem);
	@extend .btn-white;

	&::after {
		content: '\f0a9';
		position: absolute;
		right: 1rem;
		color: $red;
		font-size: 24px;
		font-weight: 400;
		font-family: $font-awesome;
		transition: 0.5s;
	}

	&:hover {
		&::after {
			color: $white;
		}
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
	display: inline-flex;
	align-items: center;

	&::after {
		content: '\f13a';
		margin-left: 10px;
		font-size: 20px;
		font-weight: 400;
		font-family: $font-awesome;
		transition: 0.5s;
	}
}
