// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$red: #a24116;
$red-light: #e1a273;
$red-dark: #6d1b0c;

$green: #27682e;
$green-light: #658158;
$green-neon: #c3d000;
$green-dark: #3d473e;

$cream: #cfcbbc;
$cream-light: #ebe9d7;
$cream-dark: #b1ae8d;

$white: #fff;
$black: #000;

// theme-colors
$primary: $red;
$secondary: $green;

// body
$body-bg: $cream-light;
$body-color: $black;

// links
$link-color: $primary;
$link-decoration: underline;
$link-hover-color: darken($link-color, 50%);

// typography
@import url('https://fonts.googleapis.com/css2?family=Averia+Libre:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Radio+Canada:ital,wght@0,300..700;1,300..700&display=swap');
$font-family-primary: 'Radio Canada', sans-serif;
$font-family-secondary: 'Averia Libre', system-ui;

$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px
$line-height-base: 1.2;

$h1-font-size: $font-size-base * 3; // 48px
$h2-font-size: $font-size-base * 2.5; // 40px
$h3-font-size: $font-size-base * 2; // 32px
$h4-font-size: $font-size-base * 1.5; // 24px
$h5-font-size: $font-size-base * 1.25; // 20px
$h6-font-size: $font-size-base; // 16px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 700;
$headings-line-height: 1;
$headings-color: $green-dark;

// contextual
$light: $cream;
$dark: $green-dark;
$text-light: $white;
$text-dark: $black;

// shadow
$shadow: 0 3px 6px rgba($black, 0.15);

// carousel
$carousel-minheight: 0;
$carousel-padding: 0;
$carousel-minheight-large: 0;
$carousel-padding-large: 0;

/*
// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/
