// footer
.footer {
	margin-top: 6vh;

	// widget-123boeken
	.widget-123boeken {
		display: none; // hide
		z-index: 999;
		position: fixed;
		right: 15px;
		bottom: 15px;
		max-width: 350px;
		max-height: calc(100% - 30px);
		background: $white;
		border-radius: 15px;
		box-shadow: $shadow;
		overflow-y: scroll;

		[data-widget="123boeken"] .widget-123boeken-filter .widget-123boeken-filter-inner form .widget-123boeken-filter-item {
			flex-basis: 100%;
		}

		[data-widget="123boeken"] .widget-123boeken-overview[data-format="1"] {
			margin-top: 1em;
		}
	}

	// footer-outro-section
	.footer-outro-section {
		max-width: 1380px;
		margin: 6vh 15px;
		padding: 4.5vh 5px 6vh 5px;
		background-color: $green-dark;
		border-radius: 20px;
		color: $white;

		@media (min-width: 1410px) {
			margin-left: auto;
			margin-right: auto;
		}

		@include media-breakpoint-down(md) {
			padding-top: 30px;
		}

		.container {
			position: relative;

			.container-holder {
				max-width: 720px;

				.column {
					position: static;
				}
			}
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $white;
		}

		a:not(.btn-special) {
			color: $green-neon;
		}

		// footer-logolink
		.footer-logolink {
			@include media-breakpoint-up(md) {
				position: absolute;
				top: 0;
				right: 15px;
			}

			ul {
				@include media-breakpoint-down(md) {
					justify-content: flex-end;
					margin-bottom: 15px;
				}

				li {
					+li {
						margin-left: 15px;
					}

					font-size: 32px;

					@include media-breakpoint-down(md) {
						font-size: 24px;
					}

					a {
						&:hover {
							color: $red;
						}
					}
				}
			}
		}

		// footer-link
		.footer-link {
			ul {
				margin-top: -15px;
				column-gap: 15px;

				li {
					margin-top: 15px;

					a {
						@extend .btn;
						border-color: $green-neon;
						color: $green-neon;

						&:hover {
							background-color: $red;
							border-color: $red;
							color: $white;
						}
					}
				}
			}
		}
	}

	// footer-sitelinks-section
	.footer-sitelinks-section {
		margin: 6vh 0;

		.container {
			.container-holder {
				.column {
					@include media-breakpoint-down(sm) {
						@include make-col(6);

						// &.five {
						// 	display: none;
						// }

						// &.six {
						// 	flex: 0 0 100%;
						// 	max-width: 100%;
						// }
					}

					// @include media-breakpoint-up(xl) {
					// 	flex: 0 0 18% !important;
					// 	max-width: 18% !important;

					// 	&.five {
					// 		flex: 0 0 10% !important;
					// 		max-width: 10% !important;
					// 	}
					// }
				}
			}
		}

		// footer-text
		.footer-text:not(.footer-partners) {
			ul {
				margin: 0;
				padding: 0;
				list-style: none;

				li {
					font-size: 14px;

					a {
						display: block;
						padding: 0.25em 0;
					}
				}
			}
		}

		// footer-partners
		.footer-partners {
			ul {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				// justify-content: center;
				margin: 0 -10px;
				padding: 0;
				list-style: none;

				li {
					margin: 0 10px 10px 10px;
				}
			}
		}
	}

	// footer-address-section
	.footer-address-section {
		margin: 6vh 0;
		font-size: 14px;
		text-align: center;

		// footer-logo
		.footer-logo {
			margin-bottom: 15px;
		}
	}

	// footer-copyright-section
	.footer-copyright-section {
		padding: 30px 0;
		font-size: 14px;
		text-align: center;

		ul {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			margin: 0;
			padding: 0;
			list-style: none;

			@include media-breakpoint-down(sm) {
				flex-direction: column;
			}

			li {
				@include media-breakpoint-up(sm) {
					margin: 0 10px;
				}

				a {
					padding: 0.25em 0;
					color: $black;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}


&.contact {
	.footer {

		// widget-123boeken
		.widget-123boeken {
			display: block;
		}
	}
}
